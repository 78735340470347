// Attempt to create an enum object in JavaScript

export default class AnalyticalMethod {
	// constructor is locked by default, new instances must be created with #create() method.
	static #CONSTRUCTOR_LOCKED = true;
	// the methods we support for now.
	static #SUPPORTED_METHODS = ["MS", "ELS", "UV", "RI", "FI", "EC"];

	static MS = AnalyticalMethod.fromString("MS");
	static ELS = AnalyticalMethod.fromString("ELS");
	static UV = AnalyticalMethod.fromString("UV");
	static RI = AnalyticalMethod.fromString("RI");
	static FI = AnalyticalMethod.fromString("FI");
	static EC = AnalyticalMethod.fromString("EC");

	#name;

	constructor(name) {
		if (AnalyticalMethod.#CONSTRUCTOR_LOCKED)
			throw new Error("AnalyticalMethod is not constructable, use AnalyticalMethod.create(name) instead");
		this.#name = name;
	}

	static fromString(name) {
		if (!this.#SUPPORTED_METHODS.includes(name))
			throw new Error(`Analytical method ${name} is not supported.`);
		try {
			AnalyticalMethod.#CONSTRUCTOR_LOCKED = false;
			// this actually happens when static fields are initialized.
			if (!Object.keys(this).includes(name))
				AnalyticalMethod[name] = new AnalyticalMethod(name);
			return AnalyticalMethod[name]
		} finally {
			AnalyticalMethod.#CONSTRUCTOR_LOCKED = true;
		}
	}

	get name() {
		return this.#name;
	}
}












